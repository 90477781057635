import React, { useState, useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';






function App() {

  
  useEffect(() => {
    // Specify the URL you want to redirect to
    const targetURL = "https://onlyfans.com/action/trial/3s8gnsbnyndd1kka4gd0trrxntzbq7sg";
    window.location.href = targetURL;
  }, []);

  return (

    <div>
      <Analytics/>
    </div>


  );
}

export default App;
